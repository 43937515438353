class Controls {
    constructor() {
        this.theaterButton = false
        this.settingsButton = false
        this.chromecastButton = false
        this.nextButton = false
        this.fullscreenButton = false
        this.airplayButton = false
        this.bigPlayButton = false
        this.pictureInPictureButton = false
        this.relatedButton = false
        this.volumeButton = true
        this.shareButton = false
    }

    fromData(data) {
        this.theaterButton = data.theaterButton
        this.settingsButton = data.settingsButton
        this.chromecastButton = data.chromecastButton
        this.nextButton = data.nextButton
        this.fullscreenButton = data.fullscreenButton
        this.airplayButton = data.airplayButton
        this.bigPlayButton = data.bigPlayButton
        this.pictureInPictureButton = data.pictureInPictureButton
        this.relatedButton = data.relatedButton
        this.volumeButton = data.volumeButton
        this.shareButton = data.shareButton
    }
}

export default Controls